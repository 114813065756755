.quote {
    background: #0970e6; /* Old browsers */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzA5NzBlNiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwZGRiZWQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(left,  #0970e6 0%, #0ddbed 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #0970e6 0%,#0ddbed 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #0970e6 0%,#0ddbed 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0970e6', endColorstr='#0ddbed',GradientType=1 ); /* IE6-8 */
    border-radius: .8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;

    &:before {
        background: url(../images/quote.svg) 0 0 no-repeat;
        background-size: cover;
        content: '';
        display: block;
        width: 2.2rem;
        height: 1.6rem;
    }

    @media screen and (max-width: $smartphone-middle) {
        border-radius: .6rem;
        width: 3rem;
        height: 3rem;

        &:before {
            width: 1.6rem;
            height: 1.1rem;
        }
    }
}

.video-link {
    background-color: rgba($blue2, .05);
    border-radius: 1.6rem;
    color: $blue2;
    font-size: 1.4rem;
    font-weight: $font-semibold;
    letter-spacing: .1em;
    padding: .9rem 1.5rem .7rem 4.5rem;
    position: relative;

    &:hover {
        background-color: $blue2;
        color: $white;
    }
}

.reviews {
    &-text {
        max-width: 34rem;

        h3 {
            line-height: 1.5;
        }
    }

    &-logos {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 0 5.2rem -4.8rem;

        &:last-child {
            margin-bottom: 0;
        }

        li {
            margin-left: 4.8rem;
        }
    }

    &-one {
        display: flex;
        color: $text;
        line-height: 1.29;
        padding: 5rem;

        @media screen and (max-width: $smartphone) {
            padding: 3rem;
        }

        @media screen and (max-width: $smartphone-middle) {
            display: block;
        }

        &_pic {
            border-radius: 2.4rem;
            width: 20rem;
            height: 20rem;
            overflow: hidden;

            @media screen and (max-width: $tablet-small) {
                width: 12rem;
                height: 12rem;
            }

            @media screen and (max-width: $smartphone-small) {
                border-radius: .6rem;
                width: 6rem;
                height: 6rem;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &_left {
            flex: 0 0 26.5rem;
            width: 26.5rem;
            position: relative;

            @media screen and (max-width: $tablet-small) {
                flex: 0 0 18.5rem;
                width: 18.5rem;
            }

            @media screen and (max-width: $smartphone-small) {
                width: 12.8rem;
            }

            .quote {
                position: absolute;
                top: 1.3rem;
                right: 5rem;

                @media screen and (max-width: $smartphone-small) {
                    top: .5rem
                }
            }
        }

        &_right {
            .video-link {
                position: absolute;
                top: 5rem;
                right: 5rem;
            }
        }

        &_author {
            margin-bottom: 3rem;
            padding-top: .5rem;

            .name {
                color: $blue;
                font-family: $font-family-second;
                font-size: 2.4rem;
                font-weight: 600;
                line-height: 2;
                margin-bottom: 0;

                @media screen and (max-width: $smartphone-middle) {
                    line-height: 1.5;
                }
            }
        }

        &_text {
            line-height: 1.56;
        }
    }

    &-slider {
        background-color: $white;
        border: .1rem solid $border;
        border-radius: 2.4rem;
        box-shadow: 0 .8rem 1.6rem 0 rgba($shadow, .1);
        margin-bottom: 7rem;
        position: relative;
        z-index: 5;

        @media screen and (max-width: $tablet-small) {
            margin-bottom: 16.2rem;
        }

        .owl {
            &-stage {
                display: flex;
                margin: 0 auto;
            }

            &-item {
                float: none;

                .item,
                .reviews-one {
                    height: 100%;
                }
            }

            &-nav {
                position: absolute;
                top: -9.5rem;
                right: 5rem;
                width: 15rem;
                height: 6rem;
                display: flex;
                justify-content: space-between;
                align-items: center;

                @media screen and (max-width: $tablet-small) {
                    top: auto;
                    bottom: -16.2rem;
                    right: 50%;
                    transform: translateX(50%);
                }

                button.disabled.owl {
                    &-next {
                        margin-left: 3rem;
                    }
                }
            }

            &-dots {
                bottom: -7rem;
                position: relative;
            }
        }
    }
}