.footer {
    background-color: $white;
    border-top: .1rem solid $border;
    color: $text;
    font-size: 1.2rem;
    padding: 8rem 0 6.5rem;
    position: relative;
    z-index: 5;

    @media screen and (max-width: $tablet) {
        padding: 6rem 0 5rem;
    }

    @media screen and (max-width: $smartphone-middle) {
        padding: 4rem 0;
        text-align: center;
    }

    .container {
        max-width: 127rem;
        position: relative;
    }

    &-logo {
        display: block;
        margin: 0 0 3rem;
        width: 22rem;

        @media screen and (max-width: $smartphone-middle) {
            margin: 0 auto 2rem;
        }

        img {
            width: 100%;
        }
    }

    &-copyright {
        margin-bottom: 4rem;
    }

    &-title {
        color: $blue;
        font-family: $font-family-second;
        font-size: 2rem;
        font-weight: $font-semibold;
        line-height: 1.8;
        margin-bottom: 2rem;
    }

    &-menu {
        line-height: 1.56;
        margin-bottom: 2.7rem;
        flex: 0 0 50%;
        width: 50%;

        @media screen and (max-width: $smartphone-middle) {
            width: 100%;
        }

        li {
            padding: .3rem 1rem .3rem 0;
        }

        a {
            color: $text;

            &:hover {
                text-decoration: underline;
            }
        }

        @media screen and (max-width: $tablet) {
            margin: 0 0 5rem;

            &:first-child {
                margin-bottom: 0;
            }
        }
    }

    &-nav {
        &_columns {
            display: flex;

            @media screen and (max-width: $tablet-small) {
                margin-bottom: 2.7rem;
            }

            @media screen and (max-width: $smartphone-middle) {
                display: block;
            }
        }
    }

    &-contact {
        line-height: 1.89;
        margin-bottom: 2.7rem;

        .caption {
            color: $blue;
            font-weight: $font-semibold;
            margin-right: .5rem;
        }

        a {
            color: $text;

            &:hover {
                text-decoration: underline;
            }
        }

        @media screen and (max-width: $smartphone-middle) {
            display: inline-block;
            text-align: left;
        }
    }

    &-address {
        line-height: 1.89;
    }
}
