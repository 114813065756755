* {
    margin: 0;
    padding: 0;
    outline: none !important;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;

    &:focus,
    &:active {
        outline: none !important;
    }
}

html {
    font-size: $font-size-base;
    height: 100%;
}

body {
    min-height: 100%;
    background-color: $white;
    color: $text;
    font-family: $font-family-base;
    font-size: 1.8rem;
    font-weight: $font-regular;
    line-height: 1.25;
    padding-top: 14rem;
    position: relative;

    @media screen and (max-width: $x-hd + 40) {
        padding-top: 10rem;
    }
}

a {
    color: $text;
    text-decoration: none;
    transition: all .2s linear;
}

img,
video {
    display: block;
}

ul, ul li {
    list-style: none;
}

ul,ol {
    margin: 0;
}

section {
    position: relative;
}

figure {
    margin: 0;
}

address {
    font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    line-height: 1.28;
    margin: 0;
}

h1, h2, h3 {
    color: $blue;
    font-family: $font-family-second;
    font-size: 3rem;
    font-weight: $font-semibold;
    line-height: 1.33;
}

h1 {
    margin-bottom: 2rem;

    .span1 {
        background: linear-gradient(88deg, #0970e6 1%, #0ddbed 99%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .span2 {
        background: linear-gradient(88deg, #09fec2 1%, #07deed 99%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &.span3 {
        background: linear-gradient(270deg, #07DEED -5.88%, #09FEC2 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .beta {
        background-color: $blue3;
        border-radius: .8rem;
        color: $white;
        font-size: 1.8rem;
        margin-left: .5rem;
        padding: .4rem 2.4rem;
        vertical-align: middle;
    }
}

h2 {
    margin-bottom: 1.1rem;
}

h4 {
    font-size: 2rem;
    font-weight: $font-bold;
    line-height: 1.42;
    margin-bottom: 1rem;
}

h5 {
    font-size: 2.4rem;
    font-weight: $font-regular;
    line-height: 3.8rem;
}

.font-medium {
    font-weight: 500;
}

.font-semibold {
    font-weight: 600;
}

.img {
    &-retina {
        display: none;

        @media screen and (max-width: $smartphone-middle) {
            display: block;
        }
    }

    @media screen and (max-width: $smartphone-middle) {
        &-normal {
            display: none;
        }
    }
}

@media screen and (min-width: $smartphone-middle) {
    .container {
        max-width: 137rem;
    }
}

@media only screen and (-webkit-min-device-pixel-ratio:1.5), only screen and (min-resolution:144dpi) {
  .img {
      &-retina {
          display: block;
      }

      &-normal {
          display: none;
      }
  }
}
