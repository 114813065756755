.newsletter {
    &-bg {
        background-color: $light-blue;
        border-radius: 2.4rem;
        transform: skewX(5deg);
        margin: 0 -2rem;
        padding: 4.4rem 8.8rem 4rem;

        @media screen and (max-width: $x-hd + 30) {
            margin: 0;
        }

        @media screen and (max-width: $hd) {
            padding-left: 5rem;
            padding-right: 5rem;
        }

        @media screen and (max-width: $smartphone-middle) {
            transform: skewX(.5deg);
            margin: 0 -1rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        &_in {
            transform: skewX(-5deg);
            display: flex;
            align-items: center;

            @media screen and (max-width: $smartphone-middle) {
                transform: skewX(-.5deg);
            }
        }
    }

    &-content {
        h3 {
            font-size: 3.6rem;
        }
    }

    &-pic {
        width: 30.3rem;
        height: auto;
        margin-right: 6rem;

        @media screen and (max-width: $tablet) {
            display: none;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &-form {
        &_row {
            min-height: 9.6rem;
        }

        &_content {
            display: flex;
            justify-content: space-between;
            padding-top: 3.5rem;

            @media screen and (max-width: $smartphone) {
                display: block;
            }

            .form-item {
                flex: 2;
                margin-right: 1.5rem;
                padding-bottom: 0;

                @media screen and (max-width: $smartphone) {
                    margin-right: 0;
                    padding-bottom: 3.3rem;
                }

                input {
                    background-color: $white;
                    border: 1px solid $border2;
                    border-radius: 3.2rem;
                    width: 100%;
                }
            }
        }

        .form-captcha {
            padding-top: 1.8rem;
        }
    }

    &-thankyou {
        background: rgba($white, .4);
        border-radius: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $blue;
        font-family: $font-family-second;
        font-size: 1.8rem;
        height: 6rem;
        margin-top: 3.5rem;
    }
}