.owl {
    &-carousel {
        .owl {
            &-nav {
                button.owl-next,
                button.owl-prev {
                    background: #07deed; /* Old browsers */
                    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzA3ZGVlZCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwOWZlYzIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
                    background: -moz-linear-gradient(top,  #07deed 0%, #09fec2 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top,  #07deed 0%,#09fec2 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom,  #07deed 0%,#09fec2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#07deed', endColorstr='#09fec2',GradientType=0 ); /* IE6-8 */
                    border-radius: 100%;
                    box-shadow: 0 .8rem 1.6rem 0 rgba($shadow, .1);
                    width: 6rem;
                    height: 6rem;
                    padding: .1rem !important;
                    transition: all .2s linear;

                    span {
                        background-color: $white;
                        border-radius: 100%;
                        display: block;
                        height: 100%;
                        overflow: hidden;
                        text-indent: -10rem;
                        position: relative;

                        &:before {
                            background: url(../images/ic-arrow.svg) 0 0 no-repeat;
                            background-size: cover;
                            content: '';
                            display: block;
                            width: 1rem;
                            height: 1.7rem;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    &.disabled {
                        background: $border;
                        box-shadow: none;
                        cursor: auto;

                        span {
                            &:before {
                                background: $white url(../images/ic-arrow_g.svg) 0 0 no-repeat;
                            }
                        }
                    }
                }

                button.owl-prev {
                    span {
                        &:before {
                            transform: translate(-50%, -50%) rotate(-180deg);
                        }
                    }
                }
            }

            &-dots {
                display: flex;
                justify-content: center;
            }
        }

        button.owl-dot {
            background-color: $border;
            width: 8rem;
            height: .1rem;
            margin: 0 1rem;

            &.active {
                background-color: $blue3;
            }
        }
    }
}
