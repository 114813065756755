.modal {
    z-index: 1099;

    &-backdrop {
        background-color: rgba($white, .7);
    }

    &-dialog {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        max-width: none;
        width: 100%;
        height: 100%;
    }

    &-content {
        background-color: $bg;
        border: none;
        border-radius: 0;
        color: $text;
        height: 100%;
        line-height: 1.78;

        h2 {
            margin-bottom: 3rem;
        }
    }

    &-header {
        border-bottom: none;
    }

    &-footer {
        border-top: none;
    }

    &-body {
        padding: 0;
    }

    &-logo {
        display: block;
        width: 16rem;
        position: absolute;
        top: 6.1rem;
        left: 3%;

        @media screen and (max-width: $hd + 80) {
            top: 3rem;
            left: 1.5rem;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &-close {
        background: $white url(../images/icon-close.svg) 50% 50% no-repeat;
        background-size: 2rem 2rem;
        border: .1rem solid $border;
        border-radius: .6rem;
        width: 5.5rem;
        height: 5.5rem;
        position: absolute;
        top: 4.6rem;
        right: 3%;

        @media screen and (max-width: $hd + 80) {
            top: 1.5rem;
            right: 1.5rem;
        }
    }
}