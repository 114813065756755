.contactme {
    &-row_main {
        padding: 0 0 7rem 0;
        align-items: center;
    }

    &-col {
        padding-left: 8rem;

        @media screen and (max-width: $tablet) {
            padding: 3rem 15px 0 15px;
        }
    }

    &-block {
        padding: 6rem 12rem;
        
        @media screen and (max-width: $desktop) {
            padding: 3rem 2rem 3rem 9rem;
        }

        @media screen and (max-width: $tablet) {
            padding: 3rem 0rem 3rem 6rem;
        }

        @media screen and (max-width: $smartphone-middle) {
            padding: 3rem;
        }
    }

    &-figure {
        width: 28rem;
        max-width: 100%;
        margin: 2rem 0 0 0;

        img {
            width: 100%;
            height: auto;
        }
    }

    &-bg {
        top: 0;
        height: 100%;
        margin: 0 -2rem;

        @media screen and (max-width: 1430px) {
            margin: 0 1rem;
        }

        @media screen and (max-width: $desktop) {
            margin: 0 3rem;
        }

        @media screen and (max-width: $tablet-small) {
            margin: 0;
        }
    }

    &-text {
        color: $blue;
        font-family: $font-family-second;
        font-size: 2.4rem;
        font-weight: $font-semibold;
        line-height: 3.2rem;
    }

    &-p {
        line-height: 2.8rem;
    }

    &-action {
        padding: 3rem;

        .title {
            color: $blue;
            font-family: $font-family-second;
            font-size: 2.4rem;
            font-weight: $font-semibold;
            line-height: 3.2rem;
        }
    }
    &-form {
        margin: 2rem 0 0 0;
        .form-item {
            input {
                background-color: #fff;
                border: 1px solid #dbdce1;
                border-radius: 3.2rem;
                width: 100%;
            }
        }

        .form-button {
            width: 22rem;
            height: 5.6rem;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: $tablet-small) {
                margin: auto;
            }
            
            a {
                width: 22rem;
                height: 5.6rem;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &-thankyou {
        background: rgba(206, 248, 251, 0.6);
        border-radius: 3.2rem;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: #00125e;
        font-family: "Baloo Paaji 2", Arial, Helvetica, sans-serif;
        font-size: 1.8rem;
        height: 6rem;
        margin-top: 3rem;
    }
}