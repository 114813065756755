.clients {
    &-text {
        max-width: 34rem;
        margin-top: -.8rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 1.1rem;

        @media screen and (max-width: $tablet) {
            margin: 0 0 3.5rem;
            max-width: none;
        }

        h3 {
            font-size: 3.6rem;
            line-height: 1.25;
            margin-bottom: 4rem;

            @media screen and (max-width: $tablet) {
                margin-bottom: 2rem;
            }
        }
    }

    &-right {
        float: right;
        max-width: 63rem;

        @media screen and (max-width: $tablet) {
            float: none;
        }

        .slogan {
            margin-bottom: .5rem;
            line-height: 2.4rem;

            &:first-child {
                margin: 0 0 1.3rem;
            }
        }
    }

    &-logos {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 0 4.2rem -4.8rem;

        &:last-child {
            margin-bottom: 0;
        }

        li {
            margin: 0 0 1rem 4.8rem;
        }
    }
}