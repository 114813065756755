.safari {
    h1 {
        .span1 {
            background: none;
            color: $blue2;
            -webkit-text-fill-color: unset;
        }

        .span2 {
            background: none;
            color: $turquoise;
            -webkit-text-fill-color: unset;
        }
    }

    .describe-text .title {
        .span1 {
            background: none;
            color: $turquoise;
            -webkit-text-fill-color: unset;
        }

        .span2 {
            background: none;
            color: $blue2;
            -webkit-text-fill-color: unset;
        }
    }
}