.team {
    &-one {
        color: $text;
        height: 100%;
        margin: 0 auto;
        max-width: 22.4rem;
        padding: 3.8rem 0 5rem;
        position: relative;

        &:before {
            background-color: $border;
            content: '';
            display: block;
            width: 100%;
            height: .1rem;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .link-out {
            display: block;
            width: 3rem;
            height: 3rem;
            position: absolute;
            bottom: 2rem;
            right: 2rem;
            z-index: 4;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    &-pic {
        border-radius: 2.4rem;
        margin: 0 auto 2rem;
        overflow: hidden;
        width: 100%;
        max-width: 22.4rem;
        height: 27.4rem;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-female,
    &-male {
        &:before {
            background: url(../images/icon-female.svg) 0 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &-male {
        &:before {
            background-image: url(../images/icon-male.svg);
        }
    }

    &-info {
        width: 100%;
        max-width: 22.4rem;
        margin: 0 auto;
        line-height: 1.78;
    }

    &-name {
        color: $blue;
        font-family: $font-family-second;
        font-size: 1.8rem;
        font-weight: $font-semibold;
    }

    &-cell {
        &:before {
            background-color: $border;
            content: '';
            display: block;
            width: .1rem;
            height: calc(100% - 3.8rem);
            position: absolute;
            top: 3.8rem;
            right: 0;
        }

        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
            .team-one {
                &:after {
                    background-color: $border;
                    content: '';
                    display: block;
                    width: 100%;
                    height: .1rem;
                    position: absolute;
                    top: 0;
                    left: 0;

                    @media screen and (max-width: $tablet) {
                        display: none;
                    }
                }
            }
        }

        &:nth-child(3n+1) {
            &:after {
                background-color: $border;
                content: '';
                display: block;
                width: .1rem;
                height: calc(100% - 3.8rem);
                position: absolute;
                top: 3.8rem;
                left: 0;

                @media screen and (max-width: $tablet) {
                    display: none;
                }
            }
        }

        @media screen and (max-width: $tablet) {
            &:first-child,
            &:nth-child(2) {
                .team-one {
                    &:after {
                        background-color: $border;
                        content: '';
                        display: block;
                        width: 100%;
                        height: .1rem;
                        position: absolute;
                        top: 0;
                        left: 0;

                        @media screen and (max-width: $smartphone-middle) {
                            display: none;
                        }
                    }
                }
            }

            &:nth-child(2n+1) {
                &:after {
                    background-color: $border;
                    content: '';
                    display: block;
                    width: .1rem;
                    height: calc(100% - 3.8rem);
                    position: absolute;
                    top: 3.8rem;
                    left: 0;

                    @media screen and (max-width: $smartphone-middle) {
                        display: none;
                    }
                }
            }
        }

        @media screen and (max-width: $smartphone-middle) {
            &:first-child {
                .team-one {
                    &:after {
                        background-color: $border;
                        content: '';
                        display: block;
                        width: 100%;
                        height: .1rem;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }

            &:after {
                background-color: $border;
                content: '';
                display: block !important;
                width: .1rem;
                height: calc(100% - 3.8rem);
                position: absolute;
                top: 3.8rem;
                left: 0;
            }
        }
    }

    &-rank {
        background-color: $bg;
        color: $border;
        display: block;
        font-size: 1.6rem;
        padding: 0 .5rem;
        position: absolute;
        bottom: -.9rem;
        left: 50%;
        transform: translateX(-50%);
        text-transform: uppercase;
        white-space: nowrap;
    }

    &-board {
        margin-top: -.1rem;
    }
}