.social {
    a {
        display: inline-block;
        margin-left: 1.8rem;
        vertical-align: middle;
    }
}

.section {
    &-main {
        padding-bottom: 10rem;
        overflow: hidden;
        position: relative;

        .container {
            max-width: 120rem;
            position: relative;
            z-index: 3;
        }

        h2 {
            font-weight: $font-semibold;
            line-height: 1.33;
            margin-bottom: 4.5rem;
            text-align: center;

            @media screen and (max-width: $hd + 80) {
                margin-bottom: 2.5rem;
            }

            span {
                background: linear-gradient(88deg, #0970e6 1%, #0ddbed 99%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        &_figure {
            width: 100%;
            max-width: 120rem;
            margin: 0 auto;

            img {
                width: 100%;
                height: auto;
            }
        }

        &_content {
            .main-logo {
                width: 28rem;
                margin: 0 auto 5.1rem;

                @media screen and (max-width: $x-hd + 40) {
                    margin-bottom: 3.6rem;
                }

                @media screen and (max-width: $hd + 80) {
                    margin-bottom: 2.6rem;
                }

                @media screen and (max-width: $smartphone-middle) {
                    width: 25rem;
                    margin-bottom: 4.7rem;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &_simple {
            padding-bottom: 10rem;

            .section-main_text {
                line-height: 1.58;
                max-width: 49rem;
                margin: 0 auto;
                text-align: center;

                h2 {
                    line-height: 1.33;
                    margin-bottom: 3rem;
                }

                p {
                    margin-bottom: 5rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &_top {
            line-height: 1.78;
            text-align: center;

            h1 {
                margin-bottom: 1rem;
            }

            p {
                margin: 0 auto;
                max-width: 90rem;
            }
        }

        &_btn {
            padding-top: 3rem;
            text-align: center;
        }
    }

    &-customer {
        padding-bottom: 13rem;
        z-index: 5;

        @media screen and (max-width: $smartphone-middle) {
            padding-bottom: 3rem;
        }

        h3 {
            max-width: 66rem;
            margin: 0 auto 7.9rem;
            padding: 0 1.5rem;
            text-align: center;
        }

        .container {
            max-width: 137rem;
        }
    }

    &-describe {
        margin-bottom: 12rem;
        overflow: hidden;
        z-index: 4;

        @media screen and (max-width: $smartphone-middle) {
            margin-bottom: 4rem;
        }

        .container {
            max-width: 137rem;
            position: relative;
            z-index: 2;
        }

        &_main {
            overflow: visible;
            margin-bottom: 0;

            &:after {
                background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzFiYmFmZiIgc3RvcC1vcGFjaXR5PSIwLjQiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZiZmJmZCIgc3RvcC1vcGFjaXR5PSIwLjE2Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
                background: -moz-linear-gradient(left,  rgba(27,186,255,0.4) 0%, rgba(251,251,253,0.16) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  rgba(27,186,255,0.4) 0%,rgba(251,251,253,0.16) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  rgba(27,186,255,0.4) 0%,rgba(251,251,253,0.16) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#661bbaff', endColorstr='#29fbfbfd',GradientType=1 ); /* IE6-8 */
                content: '';
                display: block;
                width: 80rem;
                height: 28rem;
                opacity: .15;
                position: absolute;
                top: 15rem;
                right: 0;
                transform: scaleX(-1) skewY(20deg);
            }

            .describe {
                &-text {
                    max-width: 48rem;

                    .btn {
                        max-width: 23rem;
                        margin-top: 5rem;
                    }
                }

                &-right {
                    &:before {
                        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZiZmJmZCIgc3RvcC1vcGFjaXR5PSIwLjE2Ii8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwOGRlZWQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
                        background: -moz-linear-gradient(left,  rgba(251,251,253,0.16) 0%, #09fdc2 100%); /* FF3.6-15 */
                        background: -webkit-linear-gradient(left,  rgba(251,251,253,0.16) 0%,#09fdc2 100%); /* Chrome10-25,Safari5.1-6 */
                        background: linear-gradient(to left,  rgba(251,251,253,0.16) 0%,#09fdc2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29fbfbfd', endColorstr='#08deed',GradientType=1 ); /* IE6-8 */
                        height: 26rem;
                        opacity: .07;
                        top: 20rem;
                        left: 28rem;
                    }
                }

                &-figure {
                    width: 100%;
                    max-width: 33.3rem;
                    height: 29rem;
                    margin-right: 12rem;
                    position: relative;

                    @media screen and (max-width: $tablet) {
                        margin-right: 0;
                    }

                    @media screen and (max-width: $smartphone-smallest) {
                        height: auto;
                    }

                    img {
                        width: auto;
                        height: 100%;

                        @media screen and (max-width: $smartphone-smallest) {
                            width: 100%;
                            height: auto;
                        }
                    }
                }

                @media screen and (max-width: $tablet) {
                    &-row {
                        padding-top: 0;
                    }
                }
            }
        }

        &_simple {
            overflow: visible;

            &:after {
                background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzFiYmFmZiIgc3RvcC1vcGFjaXR5PSIwLjQiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZiZmJmZCIgc3RvcC1vcGFjaXR5PSIwLjE2Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
                background: -moz-linear-gradient(left,  rgba(27,186,255,0.4) 0%, rgba(251,251,253,0.16) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  rgba(27,186,255,0.4) 0%,rgba(251,251,253,0.16) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  rgba(27,186,255,0.4) 0%,rgba(251,251,253,0.16) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#661bbaff', endColorstr='#29fbfbfd',GradientType=1 ); /* IE6-8 */
                content: '';
                display: none;
                width: 80rem;
                height: 35rem;
                opacity: .15;
                position: absolute;
                top: 100%;
                right: 0;
                transform: scaleX(-1) skewY(20deg);
            }

            .describe {
                &-left {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

    &-reviews {
        margin-bottom: 12rem;
        position: relative;
        z-index: 5;

        @media screen and (max-width: $smartphone-middle) {
            margin-bottom: 5rem;
        }

        .container {
            max-width: 137rem;
        }

        &_bg {
            width: calc(100% - 5rem);
            height: 100%;
            position: absolute;
            left: 2.5rem;
            bottom: -2rem;
            z-index: 4;
            background-color: $bg-gray;
            border-radius: 2.4rem;

            &2 {
                background-color: $bg-gray2;
                border-radius: 2.4rem;
                content: '';
                display: block;
                position: absolute;
                bottom: -4rem;
                left: 5rem;
                width: calc(100% - 10rem);
                height: 100%;
                z-index: 3;
            }
        }

        h3 {
            margin-bottom: 3.3rem;
            padding-left: 4.9rem;
            position: relative;

            @media screen and (max-width: $tablet-small) {
                padding-left: 0;
            }
        }

        .line {
            background-color: rgba($white, .4);
            height: .1rem;
            margin: 3.3rem 12.9rem 0 0;

            @media screen and (max-width: $tablet) {
                display: none;
            }
        }

        &_content {
            position: relative;
            z-index: 5;
        }
    }

    &-additional {
        .container {
            max-width: 141rem;
        }
    }

    &-newsletter {
        padding-bottom: 8rem;

        @media screen and (max-width: $x-hd + 30) {
            padding: 0 1rem 8rem;
        }

        @media screen and (max-width: $smartphone-middle) {
            padding: 0 1.5rem 5rem;
        }
    }

    &-properties {
        margin-bottom: 4rem;
        padding: 8.1rem 0;

        @media screen and (max-width: $tablet) {
            padding-top: 2rem;
        }

        @media screen and (max-width: $tablet-small) {
            padding-bottom: 4rem;
        }

        &_bg {
            background-color: $bg-turquoise;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .property-one {
            width: 53rem;
            height: 7rem;
            border-radius: 0 1.2rem 1.2rem 0;
            border: .1rem solid $gray2;
            border-right: none;
            background-color: $black;
            color: $white;
            font-family: $font-family-second;
            font-size: 2.4rem;
            font-weight: $font-semibold;
            margin: 2rem 0;
            padding: 1.9rem 2.9rem;
            text-align: right;

            &:nth-child(2n) {
                margin-left: -9.7rem;
            }

            @media screen and (max-width: $hd + 80) {
                margin-left: -9.7rem;

                &:nth-child(2n) {
                    margin-left: -19rem;
                }
            }

            @media screen and (max-width: $desktop + 40) {
                margin-left: -19rem;

                &:nth-child(2n) {
                    margin-left: -28rem;
                }
            }

            @media screen and (max-width: $tablet) {
                width: 81.25%;
                margin-left: 0;

                &:nth-child(2n) {
                    width: 64.7%;
                    margin-left: 0;
                }
            }

            &.turquoise {
                border-color: $turquoise3;
                background-color: $turquoise;
            }

            &.blue {
                border-color: $blue3;
                background-color: $blue3;
            }

            &.white {
                border-color: $border;
                background-color: $white;
                color: $blue;
            }
        }

        &_left {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);

            @media screen and (max-width: $tablet) {
                position: relative;
                top: auto;
                transform: none;
                margin-bottom: 1rem;
            }
        }

        .describe-right {
            &:before {
                display: none;
            }
        }
    }

    &-benefit {
        padding-bottom: 14.9rem;

        &:before {
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzFiYmFmZiIgc3RvcC1vcGFjaXR5PSIwLjQiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZiZmJmZCIgc3RvcC1vcGFjaXR5PSIwLjE2Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
            background: -moz-linear-gradient(left,  rgba(27,186,255,0.4) 0%, rgba(251,251,253,0.16) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(27,186,255,0.4) 0%,rgba(251,251,253,0.16) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(27,186,255,0.4) 0%,rgba(251,251,253,0.16) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#661bbaff', endColorstr='#29fbfbfd',GradientType=1 ); /* IE6-8 */
            content: '';
            display: block;
            width: 80rem;
            height: 43rem;
            opacity: .15;
            position: absolute;
            top: -40rem;
            right: 0;
            transform: skewY(-20deg) scale(-1);

            @media screen and (max-width: $smartphone-middle) {
                display: none;
            }
        }

        @media screen and (max-width: $tablet-small) {
            padding-bottom: 4rem;
        }

        @media screen and (max-width: $smartphone-middle) {
            padding-bottom: 2rem;
        }

        .container {
            position: relative;

            &:before {
                background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzFiYmFmZiIgc3RvcC1vcGFjaXR5PSIwLjQiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZiZmJmZCIgc3RvcC1vcGFjaXR5PSIwLjE2Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
                background: -moz-linear-gradient(left,  rgba(27,186,255,0.4) 0%, rgba(251,251,253,0.16) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  rgba(27,186,255,0.4) 0%,rgba(251,251,253,0.16) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  rgba(27,186,255,0.4) 0%,rgba(251,251,253,0.16) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#661bbaff', endColorstr='#29fbfbfd',GradientType=1 ); /* IE6-8 */
                content: '';
                display: block;
                width: 80rem;
                height: 43rem;
                opacity: .15;
                position: absolute;
                bottom: 2.3rem;
                right: 38%;
                transform: skewY(20deg) scale(-1);

                @media screen and (max-width: $tablet-small) {
                    height: 25rem;
                    right: 10%;
                    bottom: 30%;
                }
            }
        }

        h3 {
            margin-bottom: 5rem;

            @media screen and (max-width: $smartphone-middle) {
                margin-bottom: 3rem;
            }
        }
    }

    &-border {
        margin-bottom: 8rem;

        @media screen and (max-width: $smartphone-middle) {
            margin-bottom: 5rem;
        }
    }

    &-blocks {
        padding: 8rem 0 5.5rem;

        @media screen and (max-width: $smartphone-middle) {
            padding: 4rem 0 1.5rem;
        }

        h3 {
            line-height: 1.53;
            max-width: 43rem;
            margin-bottom: 2rem;
        }

        .subtitle {
            line-height: 1.78;
            margin-bottom: 5rem;
            max-width: 75rem;
        }

        .blocks-slider {
            margin: 0 -15px;
            width: calc(100% + 30px);

            .owl {
                &-stage {
                    float: none;
                    display: flex;
                    padding: 0 .5rem 2.5rem 0;
                }

                &-item {
                    &:first-child {
                        margin-left: 15px;
                    }
                }
            }
        }
    }

    &-team {
        padding: 0 1rem 12rem;
        overflow: hidden;

        h2 {
            margin-bottom: 2.5rem;
            text-align: center;

            span {
                background: linear-gradient(88deg, #0970e6 1%, #0ddbed 99%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .subtitle {
            color: $text;
            margin-bottom: 6rem;
            text-align: center;
        }

        &:before {
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzFiYmFmZiIgc3RvcC1vcGFjaXR5PSIwLjE5Ii8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmYmZiZmQiIHN0b3Atb3BhY2l0eT0iMC4xNiIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
            background: -moz-linear-gradient(left, rgba(27,186,255,0.19) 0%, rgba(251,251,253,0.16) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(27,186,255,0.19) 0%,rgba(251,251,253,0.16) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(27,186,255,0.19) 0%,rgba(251,251,253,0.16) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#301bbaff', endColorstr='#29fbfbfd',GradientType=1 ); /* IE6-8 */
            content: '';
            display: block;
            width: 108.7rem;
            height: 54rem;
            opacity: .2;
            position: absolute;
            left: 42%;
            top: 20rem;
            transform: skewY(-20deg);

            @media screen and (max-width: $hd) {
                left: 35%;
            }

            @media screen and (max-width: $smartphone-small) {
                left: 15%;
            }
        }

        &:after {
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzFiYmFmZiIgc3RvcC1vcGFjaXR5PSIwLjE5Ii8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmYmZiZmQiIHN0b3Atb3BhY2l0eT0iMC4xNiIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
            background: -moz-linear-gradient(left, rgba(27,186,255,0.19) 0%, rgba(251,251,253,0.16) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(27,186,255,0.19) 0%,rgba(251,251,253,0.16) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(27,186,255,0.19) 0%,rgba(251,251,253,0.16) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#301bbaff', endColorstr='#29fbfbfd',GradientType=1 ); /* IE6-8 */
            content: '';
            display: block;
            width: 108.7rem;
            height: 54rem;
            opacity: .2;
            position: absolute;
            right: 42%;
            top: 20rem;
            transform: skewY(20deg) scale(-1);

            @media screen and (max-width: $hd) {
                right: 35%;
            }

            @media screen and (max-width: $smartphone-small) {
                right: 15%;
            }
        }

        .container {
            max-width: 102.8rem;
            position: relative;
            z-index: 4;
        }
    }

    &-static {
        border-top: .1rem solid $border;
        padding: 4rem 0;

        ul {
            margin: 2rem 0 2rem 2rem;

            li {
                padding: 0 0 1rem 1.5rem;
                position: relative;

                &:before {
                    background-color: $text;
                    border-radius: 100%;
                    content: '';
                    display: block;
                    width: .5rem;
                    height: .5rem;
                    position: absolute;
                    top: .86rem;
                    left: 0;
                }

                .caption {
                    font-weight: $font-semibold;
                    margin: 0 0 0 -3rem;
                }
            }

            &.big {
                margin-left: 0;

                li {
                    padding-left: 4.5rem;
                }
            }
        }

        .subsection {
            margin-bottom: 3rem;
        }

        &_main {
            padding-bottom: 8rem;

            h1 {
                text-align: center;
            }

            h3 {
                font-size: 2.4rem;
                margin-bottom: 1rem;
            }

            h4 {
                color: $blue;
            }
        }
    }
}
