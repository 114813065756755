input,
button,
textarea,
select {
    background-color: transparent;
    border-color: $border;
    border-radius: .6rem;
    color: $text;
    font-family: $font-family-base;
    font-size: 1.8rem;
    height: 6rem;
    padding: 0 2rem;
    -webkit-appearance: none;

    &::-webkit-input-placeholder {
        color: $placeholder;
    }
    &::-moz-placeholder {
        color: $placeholder;
    }
    &:-moz-placeholder {
        color: $placeholder;
    }
    &:-ms-input-placeholder {
        color: $placeholder;
    }

    &:hover {
        &::-webkit-input-placeholder {
            color: $text;
        }
        &::-moz-placeholder {
            color: $text;
        }
        &:-moz-placeholder {
            color: $text;
        }
        &:-ms-input-placeholder {
            color: $text;
        }
    }
}

textarea {
    border: .1rem solid $border;
    border-radius: .6rem;
    width: 100%;
    height: 12rem;
    margin: .6rem 0 4rem;
    padding: 1.3rem 2rem;
    resize: none;
}

label {
    font-size: 1.2rem;
    line-height: 1.17;
    margin-bottom: .5rem;
}

@media (min-width: 576px) {
    .form-inline {
        label {
            margin-bottom: 1rem;
        }
    }
}

.form {
    &-item {
        padding-bottom: 3.3rem;
        position: relative;

        &.error {
            .form-control {
                border-color: rgba($error, .4);
            }
        }

        .hint {
            color: $hint;
            font-size: 1rem;
            font-weight: $font-medium;
            position: absolute;
            left: 0;
            bottom: 0;
            opacity: 0;
            transition: opacity .2s linear;
        }
    }

    &-error {
        input {
            border-color: rgba($error, .4);
            color: $error;

            &::-webkit-input-placeholder {
                color: $error;
                font-weight: $font-semibold;
            }
            &::-moz-placeholder {
                color: $error;
                font-weight: $font-semibold;
            }
            &:-moz-placeholder {
                color: $error;
                font-weight: $font-semibold;
            }
            &:-ms-input-placeholder {
                color: $error;
                font-weight: $font-semibold;
            }
        }
    }

    &-label {
        font-family: $font-family-base;
        font-size: 1.2rem;
        margin-bottom: .5rem;
    }

    &-control {
        background-color: transparent;
        border-color: $border;
        border-radius: .6rem;
        color: $text;
        font-family: $font-family-base;
        font-size: 1.8rem;
        height: 6rem;
        padding: 0 2rem;
        -webkit-appearance: none;

        &::-webkit-input-placeholder {
            color: $text;
        }
        &::-moz-placeholder {
            color: $text;
        }
        &:-moz-placeholder {
            color: $text;
        }
        &:-ms-input-placeholder {
            color: $text;
        }

        &:focus,
        &:active {
            box-shadow: none;
            color: $blue;
            outline: none !important;

            &::-webkit-input-placeholder {
                color: $white;
            }
            &::-moz-placeholder {
                color: $white;
            }
            &:-moz-placeholder {
                color: $white;
            }
            &:-ms-input-placeholder {
                color: $white;
            }

            & + .hint {
                opacity: 1;
            }
        }
    }
}
.error {
    &-text {
        color: $error;
        font-size: 1rem;
        margin: 0;
        position: absolute;
        bottom: 1.1rem;
        right: 0;
    }
}

textarea.form-control {
    border: .1rem solid rgba($gray2, .4);
    border-radius: .8rem;
    width: 100%;
    height: 8.2rem;
    margin: .6rem 0 4rem;
    padding: 1.3rem 2rem;
    resize: none;
}
