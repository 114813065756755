.btn {
    background-color: $blue2;
    border: none;
    border-radius: 5.5rem;
    color: $white;
    display: inline-block;
    font-family: $font-family-second;
    font-size: 2.2rem;
    font-weight: $font-semibold;
    height: 6.1rem;
    padding: .6rem 3.8rem 0;
    overflow: hidden;
    position: relative;
    text-transform: lowercase;

    @media screen and (max-width: $smartphone-middle) {
        display: block;
        width: 100%;
    }

    &:hover {
        background-color: $blue3;
        color: $white;
    }

    &:disabled {
        opacity: .25;
    }

    &-border {
        background: #0affc2; /* Old browsers */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMGFmZmMyIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNjIlIiBzdG9wLWNvbG9yPSIjMGFmZmMyIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNjIlIiBzdG9wLWNvbG9yPSIjMGFmZmMyIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNjMlIiBzdG9wLWNvbG9yPSIjMDhkZWVkIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNjMlIiBzdG9wLWNvbG9yPSIjMDhkZWVkIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzA4ZGVlZCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
        background: -moz-linear-gradient(-45deg,  #0affc2 0%, #0affc2 62%, #0affc2 62%, #08deed 63%, #08deed 63%, #08deed 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg,  #0affc2 0%,#0affc2 62%,#0affc2 62%,#08deed 63%,#08deed 63%,#08deed 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg,  #0affc2 0%,#0affc2 62%,#0affc2 62%,#08deed 63%,#08deed 63%,#08deed 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0affc2', endColorstr='#08deed',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */
        border-radius: 5.5rem;
        color: $blue3;
        font-weight: $font-bold;
        overflow: hidden;
        padding: .2rem;
        transition: all .2s ease-in;

        span {
            background-color: $white;
            border-radius: 5.5rem;
            display: block;
            height: 100%;
            padding: 1.2rem 6.5rem;

            @media screen and (max-width: $smartphone-middle) {
                padding-left: 2.5rem;
                padding-right: 2.5rem;
            }
        }

        &:hover {
            background: #08deed;
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMDhkZWVkIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNjIlIiBzdG9wLWNvbG9yPSIjMDhkZWVkIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNjMlIiBzdG9wLWNvbG9yPSIjMGFmZmMyIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNjMlIiBzdG9wLWNvbG9yPSIjMGFmZmMyIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzBhZmZjMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
            background: -moz-linear-gradient(-45deg,  #08deed 0%, #08deed 62%, #0affc2 63%, #0affc2 63%, #0affc2 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(-45deg,  #08deed 0%,#08deed 62%,#0affc2 63%,#0affc2 63%,#0affc2 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(135deg,  #08deed 0%,#08deed 62%,#0affc2 63%,#0affc2 63%,#0affc2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#08deed', endColorstr='#0affc2',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */
            color: $blue;
        }

        @media screen and (max-width: $smartphone-middle) {
            width: 100%;
        }
    }

    &-invert {
        background-color: $turquoise;

        &:before {
            background-color: $green3;
        }

        &:hover {
            background-color: $turquoise;
        }
    }

    &-dark {
        background-color: $blue9;

        &:before {
            background-color: $blue;
        }

        &:hover {
            background-color: $blue9;
        }
    }

    &-gradient {
        background: #0970e6; /* Old browsers */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzA5NzBlNiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwZGRiZWQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(left,  #0970e6 0%, #0ddbed 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #0970e6 0%,#0ddbed 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  #0970e6 0%,#0ddbed 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0970e6', endColorstr='#0ddbed',GradientType=1 ); /* IE6-8 */
    }
}

a.btn {
    padding-top: 1.5rem;

    &-border {
        padding: .2rem;

        span {
            padding-top: 1.2rem;
            padding-bottom: 0;
        }
    }
}
