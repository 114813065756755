.ReactModal__Overlay{
    z-index: 9000!important;
    background-color: rgba(17, 36, 55, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto!important;
  }
  
  .ReactModal__Body--open {overflow: hidden}
  
.header .btn-contact{
   color: #0affc2;
}
.header .btn-contact{
    &:hover{
      color: #0affc2;
    }
 }
.clients-text h3{
  margin-bottom: 0!important;
  margin-top: 6rem;
}
.clients-text{
  height: 50%!important;
}
.clients-text-desc{
  margin-top: 3rem!important;
}
.offer-btn{
  color: white;
}
.loader-spinner{
  margin-right: 4rem;
  margin-top: 0.5rem;
}
.lang-link{
  &:hover{
    cursor: pointer;
  }
}
.alice-carousel__stage li{
  width: auto!important;
}
.customer-logo{
  display: flex;
  align-items: center;
  justify-content: center;
}
.noWrap{
  display: inline-block;
}
