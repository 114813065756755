.additional {
    &-block {
        position: relative;
        padding: 2rem 7rem 4.5rem;

        @media screen and (max-width: $tablet) {
            padding-right: 1rem;
            padding-left: 4rem;
        }

        @media screen and (max-width: $tablet-small) {
            padding: 5rem 2rem 4.5rem;
        }
    }

    &-bg {
        background: rgb(7,222,237); /* Old browsers */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzA3ZGVlZCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwOWZlYzIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(top,  rgba(7,222,237,1) 0%, rgba(9,254,194,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(7,222,237,1) 0%,rgba(9,254,194,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(7,222,237,1) 0%,rgba(9,254,194,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#07deed', endColorstr='#09fec2',GradientType=0 ); /* IE6-8 */
        border-radius: 2.4rem;
        padding: .2rem;
        transform: skewX(-5deg);
        position: absolute;
        top: 2rem;
        left: 0;
        width: 100%;
        height: calc(100% - 6.5rem);
        overflow: hidden;

        @media screen and (max-width: $smartphone-middle) {
            transform: skewX(-.5deg);
        }

        &_in {
            background-color: $bg;
            border-radius: 2.2rem;
            height: 100%;
        }
    }

    &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        @media screen and (max-width: $tablet-small) {
            flex-direction: column;
        }
    }

    &-pic {
        width: 22.1rem;
        height: 18.5rem;
        overflow: hidden;
        margin: -2rem 0 0 3.6rem;

        @media screen and (max-width: $tablet) {
            margin-left: .6rem;
        }

        @media screen and (max-width: $smartphone-middle) {
            margin: 1.8rem 0 0;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &-text {
        color: $blue;
        font-family: $font-family-second;
        font-size: 3.6rem;
        font-weight: $font-semibold;

        @media screen and (max-width: $tablet-small) {
            margin-bottom: 3rem;
        }
    }

    &-right {
        display: flex;
        align-items: center;

        @media screen and (max-width: $smartphone-middle) {
            display: block;
        }
    }
}