.speech-bubble {
    border: 1px solid $border2;
    background-color: $white;
    border-radius: 1.6rem;
    box-shadow: 0 .8rem 1.6rem 0 rgba($shadow, .1);
    display: flex;
    align-items: center;
    height: 7rem;
    margin: 0 1.5rem 4rem;
    position: relative;
    padding: 0 3.1rem;

    &:before,
    &:after{
        content: "";
        position: absolute;
        bottom: -1.1rem;
        left: 2rem;
        border-width: 1.1rem 1.6rem 0 0;
        border-style: solid;
        border-color: $border2 transparent;
        display: block;
        width: 0;
    }

    &:after {
        bottom: -1rem;
        left: 2.1rem;
        border-width: 1rem 1.4rem 0 0;
        border-color: $white transparent;
    }

    &.right {
        &:before {
            border-width: 1.1rem 0 0 1.6rem;
            left: auto;
            right: 2rem;
        }

        &:after {
            border-width: 1rem 0 0 1.4rem;
            left: auto;
            right: 2.1rem
        }
    }

    &.simple {
        &:before,
        &:after {
            display: none;
        }
    }

    &.turquoise {
        border-color: $turquoise;

        &:before {
            border-color: $turquoise transparent;
        }
    }

    &.green {
        border-color: $green;

        &:before {
            border-color: $green transparent;
        }
    }

    &.blue {
        border-color: $blue2;

        &:before {
            border-color: $blue2 transparent;
        }
    }

    img {
        height: auto;
        max-height: 4.7rem;
    }

    &_block {
        padding: 13rem 0 9rem;
        text-align: center;

        @media screen and (max-width: $smartphone-middle) {
            padding: 4.2rem 0 0;
        }

        h3 {
            display: inline-block;
            margin-bottom: 7rem;
            padding-left: 5.8rem;
            position: relative;

            &:before {
                background: url(../images/ic-talk.svg) 0 0 no-repeat;
                background-size: cover;
                content: '';
                display: block;
                width: 3.6rem;
                height: 3rem;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }

            @media screen and (max-width: $smartphone-middle) {
                margin-bottom: 2.7rem;
                padding: 4.8rem 0 0;

                &:before {
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                }
            }
        }
    }

    &_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 86rem;
        margin: 0 auto;

        @media screen and (max-width: $smartphone-small - 54) {
            width: 28.5rem;
            max-width: none;
            flex-wrap: nowrap;
            flex-direction: column;

            .speech-bubble {
                align-self: flex-start;

                &.right {
                    align-self: flex-end;
                }

                &.simple {
                    align-self: center;
                }
            }
        }
    }
}


.customer {
    &-logo {
        background-color: $white;
        border-radius: 1.6rem;
        border: .1rem solid $border2;
        box-shadow: none;
        display: flex;
        align-items: center;
        height: 7rem;
        margin: 0 1rem 2rem;
        padding: 0 2.5rem;
        transition: all .2s linear;

        &:hover {
            border-color: $green;
            box-shadow: 0 8px 16px 0 rgba(0, 18, 45, 0.1);
        }

        img {
            height: auto;
            max-height: 4.7rem;
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
    }

    &-main {
        color: rgba($text, .8);
        letter-spacing: .1em;
        margin-bottom: 1.5rem;
        max-width: 48.8rem;

        @media screen and (max-width: $tablet) {
            max-width: none;
        }

        &_pic {
            display: flex;
            justify-content: center;
            width: 100%;
            max-width: 51.8rem;
            margin-bottom: 2.2rem;
            position: relative;

            @media screen and (max-width: $tablet-small) {
                margin: 0 auto 2.2rem;

                & + .customer-text {
                    margin: 0 auto;
                }
            }

            img {
                width: 100%;
                max-width: 37.3rem;
                height: auto;
                position: relative;
                z-index: 3;
            }

            &:before {
                background-color: $text;
                border-radius: 1.6rem;
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 24.8rem;
                left: 0;
                bottom: .9rem;
            }
        }
    }

    &-text {
        color: $gray;
        font-size: 1.4rem;
        line-height: 1.43;
        letter-spacing: .1em;
        margin-left: 3.4rem;
        max-width: 44rem;

        .title {
            color: $text;
            font-size: 1.8rem;
            font-weight: $font-semibold;
            line-height: 1.22;
            margin-bottom: .9rem;
        }
    }

    &-small {
        display: flex;
        color: $gray;
        font-size: 1.4rem;
        line-height: 2rem;
        letter-spacing: .1em;
        margin-bottom: 7rem;

        @media screen and (max-width: $smartphone-small) {
            display: block;
            margin-bottom: 4.8rem;
        }

        &_pic {
            flex: 0 0 24.2rem;
            width: 24.2rem;
            position: relative;

            @media screen and (max-width: $smartphone-small) {
                width: 100%;
                height: 18.2rem;
                margin-bottom: 2.5rem;
            }

            .bg {
                background-color: $blue;
                border-radius: 1.6rem;
                position: absolute;
                width: 15.4rem;
                height: 12.4rem;
                left: 50%;
                top: 0;
                transform: translateX(-50%);

                @media screen and (max-width: $smartphone-small) {
                    width: 100%;
                    height: 17.6rem;
                }
            }

            img {
                position: relative;
                z-index: 3;

                @media screen and (max-width: $smartphone-small) {
                    width: auto;
                    height: 100%;
                    margin: 0 auto;
                    top: -1.5rem;
                }
            }
        }

        &_right {
            max-width: 38rem;

            @media screen and (max-width: $smartphone-small) {
                max-width: none;
                padding: 0 2rem;
            }
        }

        .title {
            color: $text;
            font-size: 1.8rem;
            font-weight: $font-semibold;
            line-height: 1.22;
            margin-bottom: .9rem
        }
    }

    &-place {
        padding-top: 6.3rem;

        .customer {
            &-small {
                &_pic {
                    padding-top: 1rem;

                    @media screen and (max-width: $smartphone-small) {
                        padding-top: 0;
                    }

                    img {
                        margin-left: -1.7rem;

                        @media screen and (max-width: $smartphone-small) {
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
}