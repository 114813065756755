$black:                 #000;
$white:                 #fff;
$bg:                    #Fbfbfd;
$bg2:                   #E6FDF8;
$bg-gray:               #dfe1eb;
$bg-gray2:              #f1f2fb;
$bg-turquoise:          #cef8fb;
$text:                  #778dac;
$text2:                 #E4ECF7;
$blue:                  #00125e;
$blue2:                 #00b2ff;
$blue3:                 #086ee5;
$blue4:                 #064DB2;
$blue5:                 #065dcd;
$blue6:                 #02338F;
$blue7:                 #012D6D;
$blue8:                 #365095;
$blue9:                 #0341A3;
$light-blue:            #CEF8FB;
$green:                 #0affc2;
$green2:                #0EE0AE;
$green3:                #0eeab5;
$dark-blue:             #001461;
$dark-blue2:            #001766;
$turquoise:             #08deed;
$turquoise2:            #07dfec;
$turquoise3:            #6df1fa;
$gray:                  #778DAC;
$gray2:                 #545454;
$light-gray:            #e4e4e4;
$light-gray2:           #eef0f4;

$border:                #c4c6ce;
$border2:               #dbdce1;
$error:                 #FA6B6B;
$placeholder:           #B0C0D6;
$shadow:                #00122D;
$hint:                  #265BA5;


$x-hd:					1400px;
$hd:					1200px;
$desktop:				1024px;
$tablet: 				991px;
$tablet-small:          767px;
$smartphone:			640px;
$smartphone-middle:		575px;
$smartphone-small:		480px;
$smartphone-smallest:	375px;
