.header {
    background-color: $bg;
    border-bottom: .1rem solid $border;
    font-family: $font-family-second;
    width: 100%;
    height: 6rem;
    padding: 0 5rem;
    transition: all .2s linear;

    @media screen and (max-width: $x-hd + 80) {
        padding: 0 1.5rem;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        max-width: none;
        padding: 0 26rem;
        position: relative;

        @media screen and (max-width: $hd) {
            justify-content: flex-end;
            padding-right: 0;
        }
    }

    &-logo {
        width: 16rem;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 101;

        img {
            width: 100%;
        }

        @media screen and (max-width: $x-hd) {
            left: 0;
        }
    }

    &-main {
        display: flex;
        align-items: center;

        .nav-item_dropdown {
            li {
                padding: .4rem 0 .3rem;
            }
        }

        .btn {
            margin-left: 1.7rem;

            @media screen and (max-width: $hd) {
                margin: 0 auto;
            }
        }
    }

    .btn-contact {
        font-size: 1.8rem;
        height: 4rem;
        margin-left: 0;
        padding: .8rem 2.2rem 0;

        @media screen and (max-width: $hd) {
            border-radius: 3rem;
            height: 6rem;
            padding-top: 1.7rem;
        }
    }

    &-language {
        &_desktop {
            line-height: 1.11;
            width: 7rem;
            position: absolute;
            top: -.6rem;
            left: 0;
            padding: 1.7rem 1.5rem 1.1rem;

            .dropdown {
                &-toggle {
                    color: $blue;
                    font-weight: $font-medium;
                    margin-bottom: 1.5rem;
                    position: relative;
                    padding-right: 2.2rem !important;

                    &:after {
                        color: $blue3;
                        content: '\f0d7';
                        font-size: 1.8rem;
                        right: -.2rem;
                    }
                }

                &-menu {
                    font-family: $font-family-second;
                    margin: 3.4rem 0 0 -2.8rem;

                    &:before,
                    &:after {
                        left: 1.7rem;
                    }
                }
            }

            &.show {
                .dropdown {
                    &-toggle {
                        color: $blue3;

                        &:after {
                            margin-top: -1rem;
                        }
                    }
                }
            }

            @media screen and (max-width: $hd) {
                display: none;
            }
        }

        &_mobile {
            display: none;

            @media screen and (max-width: $hd) {
                display: block;
                color: rgba($white, .6);
                line-height: 3.2rem;
                margin-bottom: 3rem;

                ul {
                    display: flex;
                    justify-content: space-between;
                    font-weight: $font-semibold;
                    width: 100%;

                    li {
                        width: 46.7%;

                        a {
                            border: .1rem solid $border2;
                            border-radius: .6rem;
                            color: $blue;
                            display: block;
                            line-height: 2.15;
                            overflow: hidden;
                            text-align: center;
                        }

                        &.active {
                            a {
                                background-color: $bg-turquoise;
                                border-color: $turquoise2;
                            }

                            a:not([href]):hover {
                                color: $blue;
                                text-decoration: none; }
                        }
                    }
                }
            }
        }
    }

    &-right {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-left: 14.8rem;
        z-index: 3;

        @media screen and (max-width: $x-hd + 80) {
            padding-left: 8.8rem;
        }

        @media screen and (max-width: $hd) {
            position: relative;
            top: auto;
            right: auto;
            transform: none;
            display: block;
            border-top: .1rem solid $border;
            margin: 2.2rem 1.5rem 0;
            padding: 1.4rem 0 0;
        }
    }
}
