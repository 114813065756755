.modal {
    &-getoffer {
        .modal {
            &-content {
                flex-direction: row;
                justify-content: center;
                align-items: center;

                @media screen and (max-width: $tablet) {
                    display: block;
                    height: auto;
                }
            }
        }
    }

    &-welcome {
        padding: 1.5rem;

        .modal {
            &-content {
                background-color: $white;
                border-radius: 3.7rem;
                box-shadow: 0 .1rem 3.5rem rgba($black, .35);
                color: $blue;
                font-family: $font-family-second;
                font-size: 2rem;
                font-weight: $font-medium;
                line-height: 1.25;
                max-width: 78.6rem;
                height: auto;
                overflow: hidden;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) !important;

                @media screen and (max-width: $tablet - 175) {
                    max-width: calc(100% - 3rem);
                }

                @media screen and (max-width: $smartphone-middle) {
                    padding-top: 3rem;
                }

                .video-block {
                    max-width: 62.6rem;
                    margin: 0 auto -5rem;

                    @media screen and (max-width: $smartphone) {
                        margin-bottom: 0;
                    }
                }

                .title {
                    color: $blue2;
                    font-size: 2.8rem;
                    margin-bottom: 3rem;
                }

                .btn {
                    font-size: 1.6rem;
                    height: 4.9rem;
                    margin-top: 3.4rem;
                }
            }

            &-body {
                padding: 0 8rem 8rem;

                @media screen and (max-width: $smartphone) {
                    padding: 0 4rem 4rem;
                }

                @media screen and (max-width: $smartphone-small) {
                    padding: 0 2rem 4rem;
                }
            }

            &-close {
                border: none;
                background-image: url(../images/icon-close_b.svg);
                background-size: cover;
                width: 3.6rem;
                height: 3.6rem;
                padding: 0;
                top: 2.9rem;
                right: 4.4rem;
                z-index: 5;

                @media screen and (max-width: $smartphone-middle) {
                    width: 2.8rem;
                    height: 2.8rem;
                    top: 2.5rem;
                    right: 2.5rem;
                }
            }
        }
    }
}

.get-offer {
    width: 100%;
    max-width: 89rem;

    @media screen and (max-width: $tablet) {
        display: block;
        padding: 11rem 2rem 4rem;
    }

    .text {
        margin-bottom: 5rem;
    }

    .btn {
        width: 23.2rem;

        @media screen and (max-width: $smartphone-middle) {
            width: 100%;
        }
    }
}

.thank-you {
    width: 100%;
    max-width: 89rem;

    @media screen and (max-width: $tablet) {
        display: block;
        padding: 11rem 2rem 4rem;
    }

    .title {
        background: #0970e6; /* Old browsers */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzA5NzBlNiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwZGRiZWQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(left,  #0970e6 0%, #0ddbed 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #0970e6 0%,#0ddbed 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  #0970e6 0%,#0ddbed 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0970e6', endColorstr='#0ddbed',GradientType=1 ); /* IE6-8 */
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        font-family: $font-family-second;
        font-size: 3rem;
        font-weight: $font-semibold;
        margin-bottom: 3rem;
    }

    .subtitle,
    .text {
        margin-bottom: 4rem;
    }

    &_btns {
        display: flex;
        flex-wrap: wrap;

        .btn {
            font-size: 2.2rem;
            margin-top: 1rem;

            &:first-child {
                margin-right: 5rem;

                @media screen and (max-width: $smartphone-middle) {
                    margin-right: 0;
                }
            }
        }
    }
}