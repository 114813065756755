.navbar {
    &-toggler {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $light-gray2;
        border-radius: .6rem;
        width: 4rem;
        height: 4rem;
        padding: 0;
        z-index: 101;

        &-icon {
            background: url(../images/toggler.svg) 0 0 no-repeat;
            background-size: cover;
            display: block;
            width: 1.5rem;
            height: 1.5rem;
        }

        &[aria-expanded="true"] {
            background-color: $light-gray2;

            .navbar-toggler-icon {
                background: url(../images/icon-close.svg) 0 0 no-repeat;
                background-size: cover;
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }

    &-collapse {
        @media screen and (max-width: $hd) {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100% !important;
            z-index: 100;
            background: $white;
            padding-top: 5.9rem;

            &:before {
                background-color: $white;
                content: '';
                display: block;
                width: 100%;
                height: 6rem;
                position: absolute;
                top: 0;
                left: 0;
            }

            &_scroll {
                overflow-y: auto;
                height: 100%;
                padding-bottom: 5.4rem;
                position: relative;

                &:before {
                    background: url(../images/mobile-menu_l.svg) 0 0 no-repeat;
                    background-size: cover;
                    content: '';
                    display: block;
                    height: 33.2rem;
                    width: 23.2rem;
                    opacity: .2;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }

                &:after {
                    background: url(../images/mobile-menu_r.svg) 0 0 no-repeat;
                    background-size: cover;
                    content: '';
                    display: block;
                    height: 33.2rem;
                    width: 23.2rem;
                    opacity: .3;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }

            .navbar {
                &-nav {
                    border-top: .1rem solid $border;
                    padding-top: 3.1rem;
                }
            }
        }
    }
}

.nav {
    &-item {
        padding: 0 5.1rem;

        @media screen and (max-width: $x-hd) {
            padding: 0 2rem;
        }

        @media screen and (max-width: $hd) {
            padding: 0 1.5rem 1rem;
        }

        a {
            color: $blue;
            font-weight: $font-medium;
            line-height: 1.79;
            padding: 0;
            position: relative;

            &:hover {
                color: $blue3;
            }

            .beta {
                background-color: $blue3;
                border-radius: .8rem;
                color: $white;
                font-size: 1.35rem;
                margin-left: .5rem;
                padding: 0 1rem;
                vertical-align: middle;
            }
        }

        .dropdown {
            &-menu {
                width: 37.5rem;
            }

            &.show {
                .dropdown {
                    &-toggle {
                        color: $blue3;

                        &:before {
                            background-color: $blue3;
                            border-radius: 1rem 1rem 0 0;
                            content: '';
                            display: block;
                            width: 100%;
                            height: .5rem;
                            position: absolute;
                            left: 0;
                            bottom: -1.4rem;

                            @media screen and (max-width: $hd) {
                                display: none;
                            }
                        }
                    }

                    &-menu {
                        margin-top: 2.8rem;

                        @media screen and (max-width: $hd) {
                            position: relative !important;
                            margin: 1rem 0;
                            width: 100%;
                            transform: none !important;
                            border: none;
                            border-radius: 0;
                            border-left: .2rem solid $blue3;
                            box-shadow: none;
                            padding: 0 0 0 2rem;

                            &:before,
                            &:after {
                                display: none;
                            }

                            li {
                                margin-top: 1rem;

                                &:first-child {
                                    margin-top: 0;
                                }
                            }
                        }

                        @media screen and (max-width: $smartphone-middle) {
                            padding-left: .5rem;
                        }
                    }
                }
            }
        }

        &.active {
            & > a,
            .dropdown-toggle {
                color: $blue3;

                &:before {
                    background-color: $blue3;
                    border-radius: 1rem 1rem 0 0;
                    content: '';
                    display: block;
                    width: 100%;
                    height: .5rem;
                    position: absolute;
                    left: 0;
                    bottom: -1.4rem;

                    @media screen and (max-width: $hd) {
                        display: none;
                    }
                }
            }
        }
    }
}
