.describe {
    &-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        line-height: 1.56;
        max-width: 38rem;

        @media screen and (max-width: $tablet) {
            height: auto;
            margin: 0 auto 5rem;
        }

        p {
            margin: 2.8rem 0 0;
        }

        .title {
            color: $blue;
            font-family: $font-family-second;
            font-size: 3rem;
            font-weight: $font-semibold;
            line-height: 1.33;
            margin: 0;

            .span1 {
                background: #09fec2; /* Old browsers */
                background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzA5ZmVjMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwN2RlZWQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
                background: -moz-linear-gradient(left,  #09fec2 0%, #07deed 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  #09fec2 0%,#07deed 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  #09fec2 0%,#07deed 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#09fec2', endColorstr='#07deed',GradientType=1 ); /* IE6-8 */
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .span2 {
                background: #0970e6; /* Old browsers */
                background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzA5NzBlNiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwZGRiZWQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
                background: -moz-linear-gradient(left,  #0970e6 0%, #0ddbed 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  #0970e6 0%,#0ddbed 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  #0970e6 0%,#0ddbed 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0970e6', endColorstr='#0ddbed',GradientType=1 ); /* IE6-8 */
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        &_top {
            .btn {
                max-width: none;

                span {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            }
        }
    }

    &-row {
        border-bottom: .1rem solid $border;
        padding: 6rem 0;

        @media screen and (max-width: $smartphone-middle) {
            padding: 4rem 0;
        }

        &.first {
            border-top: .1rem solid $border;
        }
    }

    &-pic {
        width: 78.1rem;
        height: 44rem;
        background-color: $white;
        border-radius: 2.4rem;
        border: .1rem solid $border2;
        overflow: hidden;
        position: relative;

        @media screen and (max-width: $hd) {
            width: 100%;
            max-width: 67rem;
        }

        @media screen and (max-width: $tablet-small) {
            height: auto;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-video {
        width: 89.9rem;
        height: 38rem;
        margin: -3rem 0;

        @media screen and (max-width: $hd) {
            width: 100%;
            height: auto;
        }
    }

    &-right {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        padding-right: 2rem;
        position: relative;

        @media screen and (max-width: $hd) {
            padding-right: 0;
        }

        @media screen and (max-width: $tablet) {
            justify-content: center;
        }

        &:before {
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzFiYmFmZiIgc3RvcC1vcGFjaXR5PSIwLjQiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZiZmJmZCIgc3RvcC1vcGFjaXR5PSIwLjE2Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
            background: -moz-linear-gradient(left,  rgba(27,186,255,0.4) 0%, rgba(251,251,253,0.16) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(27,186,255,0.4) 0%,rgba(251,251,253,0.16) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(27,186,255,0.4) 0%,rgba(251,251,253,0.16) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#661bbaff', endColorstr='#29fbfbfd',GradientType=1 ); /* IE6-8 */
            content: '';
            display: block;
            width: 80rem;
            height: 43rem;
            opacity: .15;
            position: absolute;
            top: 9.3rem;
            left: 10rem;
            transform: skewY(20deg);

            @media screen and (max-width: $smartphone-middle) {
                height: 134%;
                left: -15px;
                transform: skewY(14deg);
            }
        }

        &.none {
            align-items: center;

            &:before {
                display: none;
            }
        }

        &.bottom {
            &:before {
                top: auto;
                left: auto;
                bottom: 8.7rem;
                right: -5rem;
                transform: skewY(20deg) scaleX(-1);

                @media screen and (max-width: $x-hd + 40) {
                    right: -15px;
                }

                @media screen and (max-width: $smartphone-middle) {
                    transform: skewY(14deg) scaleX(-1);
                }
            }

            &.right {
                &:before {
                    bottom: auto;
                    top: 9.3rem;
                    transform: skewY(-20deg) scaleX(-1);
                }
            }
        }
    }

    &-left {
        padding-left: 2rem;
        position: relative;

        @media screen and (max-width: $hd) {
            padding-left: 0;
        }

        @media screen and (max-width: $tablet) {
            display: flex;
            justify-content: center;
        }

        &:before {
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzFiYmFmZiIgc3RvcC1vcGFjaXR5PSIwLjQiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZiZmJmZCIgc3RvcC1vcGFjaXR5PSIwLjE2Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
            background: -moz-linear-gradient(left,  rgba(27,186,255,0.4) 0%, rgba(251,251,253,0.16) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(27,186,255,0.4) 0%,rgba(251,251,253,0.16) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(27,186,255,0.4) 0%,rgba(251,251,253,0.16) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#661bbaff', endColorstr='#29fbfbfd',GradientType=1 ); /* IE6-8 */
            content: '';
            display: block;
            width: 80rem;
            height: 43rem;
            opacity: .15;
            position: absolute;
            top: 9.3rem;
            right: 10rem;
            transform: scaleX(-1) skewY(20deg);

            @media screen and (max-width: $smartphone-middle) {
                height: 134%;
                right: -15px;
                transform: scaleX(-1) skewY(14deg);
            }
        }

        &.none {
            &:before {
                display: none;
            }
        }

        &.bottom {
            &:before {
                top: auto;
                right: auto;
                bottom: 8.7rem;
                left: -5rem;
                transform: skewY(-20deg);

                @media screen and (max-width: $smartphone-middle) {
                    left: -15px;
                    transform: skewY(-14deg);
                }
            }
        }
    }
}

.benefit {
    &-icon {
        display: block;
        height: 4.1rem;
        margin-bottom: 4rem;

        @media screen and (max-width: $smartphone-middle) {
            margin-bottom: 2rem;
        }

        img {
            height: 100%;
            width: auto;
        }
    }

    &-title {
        color: $blue;
        font-family: $font-family-second;
        font-size: 1.8rem;
        font-weight: $font-semibold;
        line-height: 1.56;
        margin-bottom: 3rem;
        max-width: 21rem;

        @media screen and (max-width: $smartphone-middle) {
            margin-bottom: 1.5rem;
        }
    }

    &-one {
        line-height: 1.78;
        max-width: 31rem;

        @media screen and (max-width: $tablet-small) {
            margin: 0 auto 6rem;
        }
    }
}

.action {
    &-text {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: $tablet-small) {
            display: block;
        }

        &_left {
            line-height: 1.56;
            max-width: 81rem;
            padding-right: 3rem;

            @media screen and (max-width: $tablet-small) {
                margin-bottom: 3rem;
                padding-right: 0;
                text-align: left;
            }
        }

        .btn {
            min-width: 23rem;
        }
    }

    &-content {
        padding: 5rem 0;
        position: relative;

        @media screen and (max-width: $smartphone-middle) {
            padding: 4rem 0;
        }
    }

    &-block {
        position: relative;
        padding: 0 7rem 7rem;

        @media screen and (max-width: $tablet) {
            padding-left: 4rem;
            padding-right: 4rem;
        }

        @media screen and (max-width: $tablet-small) {
            text-align: center;
        }

        @media screen and (max-width: $smartphone-middle) {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        h3 {
            margin-bottom: 2rem;
        }

        .newsletter-bg {
            top: 0;
        }
    }
}

.border {
    &-block {
        background: #0970e6; /* Old browsers */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzA5NzBlNiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwZGRiZWQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(left,  #0970e6 0%, #0ddbed 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #0970e6 0%,#0ddbed 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  #0970e6 0%,#0ddbed 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0970e6', endColorstr='#0ddbed',GradientType=1 ); /* IE6-8 */
        border-radius: 2.4rem;
        padding: .3rem;

        &.green {
            background: #09fec2; /* Old browsers */
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzA5ZmVjMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwN2RmZWEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
            background: -moz-linear-gradient(left,  #09fec2 0%, #07dfea 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  #09fec2 0%,#07dfea 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  #09fec2 0%,#07dfea 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#09fec2', endColorstr='#07dfea',GradientType=1 ); /* IE6-8 */
        }

        &_in {
            background-color: $bg;
            border-radius: 2.2rem;
            padding: 1.4rem 1.7rem;
        }

        &_content {
            background-color: $white;
            border: .1rem solid $border;
            border-radius: 2.4rem;
            box-shadow: 0 .8rem 1.6rem rgba($shadow, .1);
            line-height: 1.78;
            padding: 5.3rem  9rem 5.3rem 7.2rem;

            @media screen and (max-width: $tablet) {
                padding: 5rem;
            }

            @media screen and (max-width: $smartphone-middle) {
                padding: 3rem 2.5rem;
            }

            @media screen and (max-width: $smartphone-smallest) {
                padding: 3rem 1.5rem;
            }
        }

        h3 {
            margin-bottom: 2rem;
        }
    }
}

.block {
    background-color: $white;
    border: .1rem solid $border;
    border-radius: 2.4rem;
    box-shadow: 0 .8rem 1.6rem 0 rgba($shadow, .1);
    color: $gray;
    height: 100%;
    line-height: 1.78;
    width: 32rem;
    padding: 5rem 3rem;
    position: relative;

    @media screen and (max-width: $smartphone-middle) {
        width: 28rem;
        padding: 4rem 1rem 4rem 2rem;
    }

    .icon {
        display: block;
        height: 4rem;
        margin-bottom: 4rem;

        @media screen and (max-width: $smartphone-middle) {
            margin-bottom: 3rem;
        }

        img {
            height: 100%;
            width: auto;
        }
    }

    .title {
        color: $blue;
        font-family: $font-family-second;
        font-size:1.8rem;
        font-weight: $font-semibold;
        margin-bottom: 3.5rem;
        max-width: 18rem;

        @media screen and (max-width: $smartphone-middle) {
            margin-bottom: 1.5rem;
        }
    }

    &.black {
        background-color: $black;
        border: .1rem solid $gray2;
        color: $white;

        .title {
            color: $white;
        }
    }

    &.blue {
        background-color: $blue3;
        border: none;
        color: $white;

        .title {
            color: $white;
        }
    }

    &.light-blue {
        background-color: $blue2;
        border: none;
        color: $white;

        .title {
            color: $white;
        }
    }

    &.green {
        background-color: $turquoise;
        border: .1rem solid $turquoise2;
        color: $white;

        .title {
            color: $white;
        }
    }

    &-columns {
        border-top: .1rem solid $border;
        padding: 8rem 3.5rem 0;

        @media screen and (max-width: $smartphone-middle) {
            padding: 4rem 0 0;
        }

        .benefit-one {
            max-width: 52rem;
        }
    }
}