@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;500;600;700;800&display=swap');

@font-face {
  font-family: 'Aristotelica Display Trial';
  src: url('../fonts/AristotelicaDisplayTrial-DemiBo.eot');
  src: url('../fonts/AristotelicaDisplayTrial-DemiBo.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AristotelicaDisplayTrial-DemiBo.woff2') format('woff2'),
  url('../fonts/AristotelicaDisplayTrial-DemiBo.woff') format('woff'),
  url('../fonts/AristotelicaDisplayTrial-DemiBo.ttf') format('truetype'),
  url('../fonts/AristotelicaDisplayTrial-DemiBo.svg#AristotelicaDisplayTrial-DemiBo') format('svg');
  font-weight: 600;
  font-style: normal;
}


$font-family-base:       'Open Sans', Arial, Helvetica, sans-serif;
$font-family-second:     'Baloo Paaji 2', Arial, Helvetica, sans-serif;
//$font-family-second:   'Aristotelica Display Trial', 'Baloo Paaji 2', Arial, Helvetica, sans-serif;

$font-size-base:      10px;

$font-regular:        400;
$font-medium:         500;
$font-semibold:       600;
$font-bold:           700;
$font-extrabold:      800;
