.dropdown {
    position: relative;
    transition: all .3s ease-out;

    &-toggle {
        cursor: pointer;
        display: block;
        padding-right: 2.2rem !important;
        position: relative;

        @media screen and (max-width: $tablet) {
            padding-right: 3.2rem !important;
        }

        &:after {
            border: none;
            content: "\f107";
            font: normal normal normal 22px/1 FontAwesome;
            margin: 0;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-top: -.1rem;

            @media screen and (max-width: $tablet) {
                font: normal normal normal 28px/1 FontAwesome;
                right: .8rem;
            }
        }
    }

    &-item {
        border-radius: .6rem;
        color: $blue;
        font-size: 1.8rem;
        font-weight: $font-medium;
        line-height: 2.25;
        padding: 0 1rem 0 1.7rem !important;
        white-space: normal;

        &:hover,
        &:focus,
        &:active,
        &.active{
            background-color: $light-gray2;
            color: $blue3;

            @media screen and (max-width: $tablet) {
                background-color: transparent;
            }
        }
    }

    &-menu {
        border-color: $border;
        border-radius: .6rem;
        box-shadow: 0 .8rem 1.6rem 0 rgba($shadow, .12);
        float: none;
        font-size: 1.8rem;
        font-weight: $font-medium;
        margin: 1.4rem 0 0 -3.1rem;
        padding: 2rem 1.5rem;
        min-width: 8.2rem;

        &:before,
        &:after{
            content: "";
            position: absolute;
            top: -.6rem;
            left: 3.5rem;
            border-width: 0 .8rem .5rem;
            border-style: solid;
            border-color: $border transparent;
            display: block;
            width: 0;
        }

        &:after {
            top: -.5rem;
            border-color: $white transparent;
        }
    }

    &.show {
        .dropdown {
            &-toggle {
                &:after {
                    margin-top: -1.1rem;
                    transform: rotate(-180deg);
                }
            }
        }
    }
}
