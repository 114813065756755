.section {
    &-main-cmp {

        &-top {
            margin: 0 0 2rem 0;

            h1 {
                font-weight: $font-extrabold;
                font-size: 3.6rem;
                margin: 0 0 2rem 0;
            }

            &-logos {
                margin-top: 1.5rem;
                display: flex;
                justify-content: center;

                &-list {
                    display: flex;
                    align-items: center;

                    li {
                        position: relative;
                        padding: 0 2rem;
                    }

                    li:not(:last-child)::after {
                        content: '';
                        border-left: 1px solid #778DAC;
                        height: 1.3rem;
                        position: absolute;
                        display: block;
                        right: 0;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                &_procore {
                    width: 10rem;
                }

                &_docusign {
                    width: 8.4rem;
                }
            }

            &-button {
                padding: 0;
                margin: 4rem 0 0 0;
                display: flex;
                justify-content: center;
                
                a {
                    width: 22rem;
                    height: 5.6rem;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }


        }
    }

    &-border-cmp {
        margin: 0 0 12rem 0;
        
        h3 {
            font-size: 3.6rem;
        }
    }

    &-describe-cmp {

        margin: 0 0 8rem 0;

        .describe-text-cmp {
            max-width: none;
        
            &-block {
            margin: 0 0 2.4rem 0;
            }
        
            &-secured {
                background: $bg-gray2;
                border-radius: 2.4rem;
                display: flex;
                align-items: center;
                padding: 1.5rem 1.5rem 1.5rem 3rem;
        
                .secured-icon {
                    margin: 0 2rem 0 0;
                }
        
                .secured-text {
                    font-size: 1.4rem;
                    color: $blue;
                }
            }
        }

        .title {
            margin-bottom: 2.4rem;
            font-size: 3.6rem;
            line-height: 4.8rem;
        }

        .title-video {
            color: $blue;
            font-family: $font-family-second;
            font-size: 2.4rem;
            font-weight: $font-semibold;
            line-height: 3.2rem;
            margin: auto;
            max-width: 50rem;
        }

        .describe-row-cmp {
            padding: 10rem 0;

            &-figure {
                width: 100%;
                max-width: 80rem;
                height: auto;
            }
        }

        .describe-cmp-pic {
            width: 100%;
            max-width: 68rem;
            height: auto;
        }
    }

    &-benefit-cmp {
        
        padding: 0 0 8rem 0;

        h3 {
            font-size: 3.6rem;
            padding: 0 5rem;
        }

        .benefit {
            &-title-cmp {
                font-size: 2.4rem;
            }

            &-block-cmp {
                padding: 0 5rem 7rem;
                border-bottom: 0.1rem solid #c4c6ce;
            }
        }

        p {
            font-weight: $font-semibold;
        }
    }
}



